import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["bar", "total"];
  static values = {
    variantId: Number,
    goal: Number,
  };

  async barTargetConnected(bar) {
    const id = this.variantIdValue;
    const balance = (await this.balance(id))?.data?.balance || 0;
    const percent = (balance / this.goalValue) * 100;

    bar.style.width = `${percent}%`;
    bar.ariaValueNow = balance;
  }

  get balances() {
    if (!window.balances) window.balances = {};

    return window.balances;
  }

  async totalTargetConnected(total) {
    const id = this.variantIdValue;
    const balance = (await this.balance(id))?.data?.balance || 0;

    total.innerHTML = balance.toLocaleString(this.locale);

    this.dispatch("general", { detail: { balance } });
  }

  async balance(id) {
    if (!this.balances[id]) {
      this.balances[id] = await window.spree.sutty.payWhatYouCanBalance({ id });
    }

    return this.balances[id];
  }

  get locale() {
    return document.querySelector("html").lang;
  }
}
