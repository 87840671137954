import { Controller } from "@hotwired/stimulus";

import L from "leaflet";

export default class extends Controller {
  static targets = ["map", "point", "svg", "svgSection"];
  static values = {
    lat: Number,
    lng: Number,
    zoomLevel: Number,
    username: String,
    styleId: String,
    accessToken: String,
    showOnly: String,
  };

  connect() {
    this.visiting = false;
    this.layer;
    this.canales;
  }

  pointTargetConnected(point) {
    const coords = this.coords_from(point);

    if (!coords) return;

    const marker = this.marker(coords, point);

    marker._icon.innerHTML = point.innerHTML;

    marker.on("click", (event) => {
      if (this.visiting) return;

      this.visiting = true;
      Turbo.visit(point.dataset.url);
    });

    this.markers[point.id] = {
      marker,
      timeout: setTimeout(() => {
        marker._icon.classList.remove("o-0");
        marker._icon.classList.add("o-5");
      }, 1000),
    };
  }

  pointTargetDisconnected(point) {
    const marker = this.markers[point.id];

    if (!marker) return;

    this.map.removeLayer(marker.marker);
    clearTimeout(marker.timeout);
  }

  svgSectionTargetConnected(section) {
    if (this.showOnlyValue.length === 0) return;
    if (section.id === this.showOnlyValue) return;

    section.classList.add("d-none");
  }

  disconnect() {
    this.map.removeLayer(this.layer);
  }

  get markers() {
    if (!this._markers) this._markers = {};

    return this._markers;
  }

  /*
   * Obtener las coordenadas del mapa, por defecto el centro de CABA (!)
   *
   * @return [Array]
   */
  get coords() {
    return [this.latValue, this.lngValue];
  }

  get layer() {
    if (!this._layer) {
      this._layer = L.tileLayer(
        "https://api.mapbox.com/styles/v1/{username}/{style_id}/tiles/{tilesize}/{z}/{x}/{y}@2x?access_token={access_token}",
        {
          username: this.usernameValue,
          style_id: this.styleIdValue,
          tilesize: 512,
          access_token: this.accessTokenValue,
        }
      ).addTo(this.map);
    }

    return this._layer;
  }

  get canales() {
    if (!this._canales) {
      const bounds = [
        [2.691473699065877, -80.99921297281982],
        [-4.9021840041826685, -75.26028111577035],
      ];

      this._canales = L.svgOverlay(this.svgTarget, bounds).addTo(this.map);
    }

    return this._canales;
  }

  get map() {
    if (!this._map) {
      this._map = L.map(this.mapTarget, {
        attributionControl: false,
        touchZoom: false,
        scrollWheelZoom: false,
        doubleClickZoom: false,
      }).setView(this.coords, this.zoomLevelValue);
    }

    return this._map;
  }

  icon(point) {
    const width = parseInt(point.dataset.width);
    const height = parseInt(point.dataset.height);

    return L.divIcon({
      className: "transition o-0",
      iconSize: [width, height],
      iconAnchor: [width / 2, height / 2],
      html: "<div></div>",
    });
  }

  /*
   * Obtener coordenadas del dataset de un elemento, si alguna no es
   * válida devuelve undefined
   *
   * @return [Array,undefined]
   */
  coords_from(element) {
    const lat = parseFloat(element.dataset.lat);
    const lng = parseFloat(element.dataset.lng);

    if (isNaN(lat) || isNaN(lng)) return undefined;

    return [lat, lng];
  }

  marker(coords, point) {
    return L.marker(coords, {
      icon: this.icon(point),
      title: point.dataset.title,
    }).addTo(this.map);
  }
}
