import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["monto", "propina"];
  static values = {
    proporcion: Number,
  };

  connect() {}

  update(event = undefined) {
    if (!this.hasMontoTarget) return;
    if (!this.hasPropinaTarget) return;

    const monto = +this.montoTarget.value;

    if (isNaN(monto)) return;

    this.propinaTarget.value = Math.round((monto / 100) * this.proporcionValue);
  }
}
