import { CartBaseController } from "./cart_base_controller";

/*
 * Al pagar lo que podamos, primero hay que crear una orden y luego
 * contactarse con la APIv2 para generar la variante con el precio que
 * queramos agregar.  Agregamos la variante al carrito y lanzamos el
 * proceso de pago.
 */
export default class extends CartBaseController {
  static targets = ["form", "variant"];
  static addressFields = [
    "country_id",
    "firstname",
    "lastname",
    "city",
    "address1",
    "phone",
    "zipcode",
  ];
  static values = {
    variantId: Number,
    currency: String,
    price: Number,
    firstname: String,
  };

  connect() {
    this.paymentMethodByCurrency = {
      ARS: ["Spree::PaymentMethod::MercadoPago"],
      USD: ["Spree::PaymentMethod::Paypal", "Spree::PaymentMethod::Stripe"],
    };

    this.formdata_event = this._formdata_event.bind(this);
    this.formTarget.addEventListener("formdata", this.formdata_event);
  }

  disconnect() {
    this.formTarget.removeEventListener("formdata", this.formdata_event);
  }

  _formdata_event(event) {
    this.processPayment(event.formData);
  }

  /*
   * Realiza todos los pasos:
   *
   * * Crear pedido
   * * Crear variante con el monto y moneda
   * * Agregar al pedido
   * * Agregar dirección al pedido
   * * Obtener métodos de envío
   * * Obtener métodos de pago
   * * Pagar
   * * Reenviar a confirmación
   * * Ejecutar el pago (si aplica)
   */
  pay(event = undefined) {
    event?.preventDefault();
    event?.stopPropagation();

    if (!this.formTarget.checkValidity()) {
      this.formTarget.classList.add("was-validated");
      return;
    }

    this.formTarget.classList.remove("was-validated");

    if ("FormDataEvent" in window) {
      new FormData(this.formTarget);
    } else {
      this.processPayment(new FormData(this.formTarget));
    }
  }

  async processPayment(formData) {
    this.formDisabled = true;

    const orderToken = await this.tempCartCreate();
    const quantity = 1;
    const include = "line_items";
    const currency = formData.get("currency");
    const email = formData.get("email");
    const ship_address_attributes = {};

    for (const field of this.constructor.addressFields) {
      ship_address_attributes[field] = formData.get(field);
    }

    const bill_address_attributes = ship_address_attributes;
    const confirmation_delivered = false;
    const custom_return_url = this.customReturnUrl();

    // Configurar la moneda del pedido
    let response = await this.spree.sutty.updateOrder(
      { orderToken },
      { currency, confirmation_delivered, custom_return_url }
    );

    if (response.status > 299) {
      console.error(response);
      this.formDisabled = false;
      return;
    }

    // Crear una variante con el monto
    for (const variant of this.variantTargets) {
      let variant_id = variant.dataset.variantId;
      const price = variant.value;

      const payWhatYouCanResponse = await this.spree.sutty.payWhatYouCan(
        { orderToken },
        { variant_id, price, currency, quantity }
      );

      variant_id = payWhatYouCanResponse.data.id;

      if (!variant_id) {
        this.formDisabled = false;
        console.error("No se pudo generar la variante", {
          variant_id,
          price,
          currency,
          quantity,
        });
        return;
      }

      // Agregar al carrito
      response = await this.spree.cart.addItem(
        { orderToken },
        { variant_id, quantity, include }
      );

      if (response.isFail()) {
        this.handleFailure(response);
        this.formDisabled = false;
        return;
      }
    }

    // Actualizar la dirección
    response = await this.spree.checkout.orderUpdate(
      { orderToken },
      { order: { email, ship_address_attributes, bill_address_attributes } }
    );

    if (response.isFail()) {
      this.handleFailure(response);
      this.formDisabled = false;
      return;
    }

    // Obtener los medios de envío
    response = await this.spree.checkout.shippingMethods(
      { orderToken },
      { include: "shipping_rates" }
    );

    if (response.isFail()) {
      this.handleFailure(response);
      this.formDisabled = false;
      return;
    }

    // Elegir medio de envío
    response = await this.spree.checkout.orderUpdate(
      { orderToken },
      {
        order: {
          shipments_attributes: [
            {
              id: response.success().data[0].id,
              selected_shipping_rate_id: response
                .success()
                .included.filter((x) => x.type == "shipping_rate")[0].id,
            },
          ],
        },
      }
    );

    // Elegir medio de pago
    response = await this.spree.checkout.paymentMethods({ orderToken });

    if (response.isFail()) {
      this.handleFailure(response);
      this.formDisabled = false;
      return;
    }

    const payment_method_id = response
      .success()
      .data.find((x) =>
        this.paymentMethodByCurrency[currency].includes(x.attributes.type)
      ).id;

    response = await this.spree.checkout.orderUpdate(
      { orderToken },
      {
        order: { payments_attributes: [{ payment_method_id }] },
        payment_source: {
          [payment_method_id]: {
            name: "Pepitx",
            month: 12,
            year: 2021,
          },
        },
      }
    );

    if (response.isFail()) {
      this.handleFailure(response);
      this.formDisabled = false;
      return;
    }

    response = await this.spree.checkout.complete({ orderToken });

    if (response.isFail()) {
      this.handleFailure(response);
      this.formDisabled = false;
      return;
    }

    // Reenviar al medio de pago
    const checkoutUrls = await this.spree.sutty.getCheckoutURL({ orderToken });
    const redirectUrl = checkoutUrls.data[0];

    this.visit(redirectUrl);

    // Volver
  }

  async tempCartCreate() {
    const response = await this.spree.cart.create();

    // If we fail here it's probably a server error, so we inform the
    // user.
    if (response.isFail()) {
      this.handleFailure(response);
      return;
    }

    return response.success().data.attributes.token;
  }

  // @return [String]
  customReturnUrl() {
    const url = new URL(window.location.href);
    url.searchParams.set("open", "");

    return url.toString();
  }
}
