import { CartBaseController } from "./cart_base_controller";

/*
 * Populates a country field where users can type to filter and select
 * from a predefined list.
 */
export default class extends CartBaseController {
  // All are required!
  static targets = ["id", "list", "name"];

  async connect() {
    const countries = await this.countries();

    countries.forEach((country) => {
      const option = document.createElement("option");

      option.value = country.attributes.name;
      option.dataset.id = country.id;

      this.listTarget.appendChild(option);
    });

    this.input_event = this._input_event.bind(this);
    this.invalid_event = this._invalid_event.bind(this);
    this.change_event = this._change_event.bind(this);

    // Only allow names on this list
    this.nameTarget.pattern = countries.map((x) => x.attributes.name).join("|");
    this.nameTarget.addEventListener("input", this.input_event);
    this.nameTarget.addEventListener("invalid", this.invalid_event);

    // When the input changes we update the actual value and also the
    // state list via an Event
    this.nameTarget.addEventListener("change", this.change_event);

    // The input is disabled at this point
    this.nameTarget.disabled = false;
  }

  disconnect() {
    this.nameTarget.removeEventListener("input", this.input_event);
    this.nameTarget.removeEventListener("invalid", this.invalid_event);
    this.nameTarget.removeEventListener("change", this.change_event);
  }

  _input_event(event) {
    this.nameTarget.setCustomValidity("");
  }

  _invalid_event(event) {
    this.nameTarget.setCustomValidity(window.site.i18n.countries.validation);
  }

  _change_event(event) {
    const value = this.nameTarget.value.trim();

    if (value === "") return;

    const option = this.options.find((x) => x.value == value);

    // TODO: If no option is found, mark the field as invalid
    if (!option) return;

    this.idTarget.value = option.dataset.id;
  }

  get options() {
    if (!this._options) {
      this._options = Array.from(this.nameTarget.list.options);
    }

    return this._options;
  }

  /*
   * Fetch the country list from storage or from API
   */
  async countries() {
    const countries = JSON.parse(this.storageTemp.getItem("countries"));

    if (countries) return countries;

    const response = await this.spree.countries.list();

    // TODO: Show error message
    if (!response.success()) return;

    this.storageTemp.setItem(
      "countries",
      JSON.stringify(response.success().data)
    );

    return response.success().data;
  }
}
