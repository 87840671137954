import { Controller } from "@hotwired/stimulus";
import L from "leaflet";

/*
 * Add Leaflet's CSS to your project and create a map with an SVG
 * overlay.  By dragging the two markers you can place an SVG image on
 * a map.  Check the console for the coordinates.
 *
 * <div data-controller="place-svg-in-map">
 *   <div data-place-svg-in-map-target="map" style="width: 100vw; height: 100vh"></div>
 *
 *   <svg data-place-svg-in-map-target="svg"></svg>
 * </div>
 *
 * LICENSE: Antifa-MIT
 */
export default class extends Controller {
  static targets = ["map", "svg"];

  connect() {
    this.layer();
    this.svgOverlay();
  }

  layer() {
    if (!this._layer) {
      this._layer = L.tileLayer(
        "https://wms.ign.gob.ar/geoserver/gwc/service/tms/1.0.0/capabaseargenmap@EPSG%3A3857@png/{z}/{x}/{y}.png",
        {
          maxZoom: 21,
          tms: true,
          attribution: "",
        }
      ).addTo(this.map);
    }

    return this._layer;
  }

  svgOverlay() {
    if (!this._svgOverlay) {
      this.bounds = [
        [1.3315737587824288, -80.8714248239994],
        [-4.68358941200817, -75.3562881052494],
      ];

      const draggable = true;

      for (const boundIndex in this.bounds) {
        L.marker(this.bounds[boundIndex], { draggable })
          .addTo(this.map)
          .on("drag", (event) => {
            this.bounds[boundIndex] = event.latlng;
            this.svgOverlay.setBounds(this.bounds);

            console.log("SVG Bounds:", this.bounds);
          });
      }

      this._svgOverlay = L.svgOverlay(this.svgOverlayTarget, this.bounds).addTo(
        this.map
      );
    }

    return this._svgOverlay;
  }

  get map() {
    if (!this._map) {
      this._map = L.map(this.mapTarget, {}).setView([-1.34, -79.321], 8);
    }

    return this._map;
  }
}
