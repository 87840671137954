import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["total"];
  static values = {
    total: { type: Number, default: 0 },
    updating: { type: Boolean, default: false },
  };

  update({ detail: { balance } }) {
    const interval = setInterval(() => {
      if (this.updatingValue) return;

      this.updatingValue = true;

      this.totalValue = this.totalValue + balance;
      this.totalTarget.innerHTML = this.totalValue.toLocaleString(this.locale);

      this.updatingValue = false;

      clearInterval(interval);
    }, 100);
  }

  get locale() {
    return document.querySelector("html").lang;
  }
}
